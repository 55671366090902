import { useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import { addPageInfoToSerachParams } from '@helpers/utils';

import { yupResolver } from '@hookform/resolvers/yup';
import useTranslation from '@hooks/useTranslation';
import { laravelRouteStartFree } from '@static_components/laravelLinks';
import * as Yup from 'yup';

interface FreeTrialFormProps {
    styles?: any;
    version?: string;
    pageinfo?: string;
    showFormInfo?: boolean;
    info?: string;
    button?: string;
    placeholder?: string;
}

type FormValues = {
    email: string;
};

type GetCoordsParams = Record<'city' | 'appid' | 'limit', string>;

export default function FreeTrialForm({ styles, version, pageinfo, showFormInfo = false, info, button, placeholder }: FreeTrialFormProps): JSX.Element {
    const { t } = useTranslation('base/_blocks/free-trial-form');
    const { locale } = useRouter();
    const formInfo = info || t('freeTrialFormStartEmailInfo');
    const formButton = button || t('freeTrialFormStartSubmit');
    const formEl = useRef<HTMLFormElement>(null);
    const { pageProperties } = useAppContext();
    const paramsObject = {
        version: version,
        pageinfo: pageinfo,
    };

    Object.keys(paramsObject).forEach((key) => {
        if (paramsObject[key as keyof typeof paramsObject] === undefined) {
            delete paramsObject[key as keyof typeof paramsObject];
        }
    });

    let params = new URLSearchParams(paramsObject as Record<string, string>).toString();
    params = params !== '' ? '?' + params : '';

    const schema = Yup.object().shape({
        email: Yup.string().email(t('validation:email')).max(128, t('validation:invalid')).required(t('validation:required')),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>({ resolver: yupResolver(schema) });

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        if (formEl.current) {
            formEl.current.submit();
        }
    };

    return (
        <form
            ref={formEl}
            id="free-trial-form"
            name="free-trial-form"
            method="post"
            action={addPageInfoToSerachParams(pageProperties, laravelRouteStartFree(locale) + params)}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <fieldset>
                <div>
                    <input
                        {...register('email')}
                        aria-label={t('freeTrialFormStartEmail')}
                        type="email"
                        name="email"
                        placeholder={placeholder || t('freeTrialFormStartEmail')}
                        data-test="short-free-trial-email-input"
                    />
                    {errors.email && <div className={styles.error}>{errors.email?.message}</div>}
                </div>
                {showFormInfo && <small>{formInfo}</small>}
            </fieldset>
            <div className={styles['btn-wrap']}>
                <button
                    type="submit"
                    data-test="short-free-trial-submit"
                    className={styles.btn}
                >
                    {formButton}
                </button>
            </div>
        </form>
    );
}
